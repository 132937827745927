module.exports = {
  general: {
    generic_page_title: "Weblica",
    cookie_consent: {
      title: "Kolačići",
      msg: [
        "<p><b>Ova stranica koristi kolačiće</b></p>",
        "<p>Kolačiće koristimo isključivo za praćenje statistike posjete stranica.</p>",
        "<p>Klikom na ikonu kolačića na dnu stranice, dozvolu možete promijeniti u bilo kojem trenutku.</p>",
        '<p>Ako želite saznati više, provjerite našu <a href="{{url}}" target="_blank">Zaštitu privatnosti</a>.</p>',
      ],
      btn_yes: "Prihvaćam",
      btn_no: "Odbijam",
    },
    inactive_event: "Ove godine preskačemo!",
    inactive_survey: "Upitnik nije aktivan za navedeni događaj!",
    no_data: "Nema podataka!",
    no_data_list_intro:
      "Prikupljamo i pripremamo podatke , a za to vrijeme, evo nešto da popuni trenutnu prazninu:",
    no_data_list: [
      "Software and cathedrals are much the same — first we build them, then we pray.",
      "Don’t worry if it doesn’t work right. If everything did, you’d be out of a job.",
      "Walking on water and developing software from a specification are easy if both are frozen.",
      "C programmers never die. They are just cast into void.",
      "Some people when confronted with a problem think: ”I know, I’ll use regular expressions.”. Now they have two problems.",
      "If the box says, ‘This software requires Windows XP or better,’ does that mean it’ll run on Linux?",
      "There are only two hard things in computer science: cache invalidation and naming things.",
      "Hardware is made to last. Software is made to change. Change is the only thing that lasts. Software wins.",
      "There is no Ctrl-Z in life.",
      "Software and cathedrals are much the same — first we build them, then we pray.",
      "Don’t worry if it doesn’t work right. If everything did, you’d be out of a job.",
      "Perl: The only language that looks the same before and after RSA encryption.",
    ],
    toggle_theme: "Promjena teme",
    inaccessible_page: "Ova stranica trenutno nije dostupna!",
    secret_page: "Ova stranica neće biti generirana za javnost!",
    survey_button: "Popuni upitnik", // i reci nam u čemu možemo biti još bolji!",
    survey_button_short: "Popuni upitnik",
    survey_button_last_year: "Popuni upitnik za prošlu godinu",
    finished_title: [ // default/fallback
      "<p><b>Hvala!</b><br/>Vidimo se opet sljedeće godine!</p>"
    ],
    finished_text: [ // default/fallback
      'Prati nas ovdje ili se pridruži našoj <a href="#newsletter">mail listi</a> i budi u tijeku!',
    ],
    form: {
      full_name: "Ime i prezime",
      full_name_not_required: "Ime i prezime (nije obavezno)",
      full_name_alt: "Ime i prezime",
      full_name_alt_not_required: "Puno ime (nije obavezno)",
      first_name: "Ime",
      last_name: "Prezime",
      title: "Zvanje",
      function: "Funkcija",
      email: "E-mail",
      pin: "PIN",
      pin_placeholder_visitor: "Upišite PIN koji ste dobili od nas",
      phone: "Mobitel",
      company: "Tvrtka",
      web: "Web",
      message: "Poruka",
      optional_placeholder: "(nije obavezno)",
      bool_yes: "Da",
      bool_no: "Ne",
      option_null: "(ništa od navedenog)",
      clear_all_values: "Obriši",
      send_data: "Šalji",
      info_code: "Molimo da budete pristojni i da slijedite naša <a href='{{url}}' target='_blank'>Pravila ponašanja</a>.",
      info_privacy: 'Prije slanja preporučujemo provjeriti našu <a href="{{url}}" target="_blank" rel="noreferrer">Politiku privatnosti</a> o tome kako upravljamo privatnim podacima.',
      msgs: {
        sending_data: "Slanje",
        data_sent: "Uspješno poslano!",
        full_name_missing: "Nedostaje ime i prezime!",
        first_name_missing: "Nedostaje ime!",
        last_name_missing: "Nedostaje prezime!",
        email_missing: "Nedostaje e-mail!",
        email_format: "E-mail nije pravilno upisan!",
        pin_missing: "Nedostaje PIN!",
        pin_format: "PIN nije pravilno upisan!",
        pin_unknown: "PIN nije prepoznat!",
        phone_missing: "Nedostaje broj mobitela!",
        phone_format: "Broj mobitela nije pravilno upisan!",
        server_error: "Greška na poslužitelju!",
        exists: "Podaci koje ste poslali već postoje!",
        required_missing: "Jedno ili više obaveznih polja nije popunjeno!"
      },
    }
  },
  pages: {
    home: {
      title: "Pogled u svijet web tehnologija",
      meta: {
        description:
          "Godišnja regionalna konferencija o web tehnologijama s popratnim događajima poput hackathona i radionica",
        keywords:
          "weblica, hacklica, worklica, konferencija, hackathon, radionica, workshop, IT, developer, software, devops, web3, php, javascript, node, .net, css, html, react, vue, angular, razvoj, kolaboracija, dizajn, implementacija",
      },
      view_event: "Pregledaj događaj",
      register_team: "Prijavi svoj tim",
      get_tickets: "Kupi ulaznice",
      register_lecture: "Prijavi predavanje",
      sponsor: "Postani sponzor",
    },
    code: {
      title: "Pravila ponašanja",
      meta: {
        description:
          "Pravila ponašanja mogu biti vrlo jednostavna - Budite izvrsni jedni prema drugima",
        keywords: "pravilna, ponašanja, ponašanje",
      },
    },
    terms: {
      title: "Uvjeti korištenja",
      meta: {
        description: "Uvjeti korištenja",
        keywords: "uvjeti, korištenje, korištenja",
      },
    },
    privacy: {
      title: "Zaštita privatnosti",
      meta: {
        description: "Zaštita privatnosti",
        keywords: "zaštita, privatnost, privanosti",
      },
    },
    safety: {
      title: "Zdravlje i sigurnost",
      meta: {
        description: "Zdravlje i sigurnost",
        keywords: "zdravlje, sigurnost",
      },
    },
    press: {
      title: "Novine i materijali",
      meta: {
        description: "Novine i materijali",
        keywords: "novine, materijali, logotipovi",
      },
      reg_title: "Registracija",
      reg_msg:
        "Novinari su dobrodošli na našim događajima, potpuno besplatno. Samo je potrebno popuniti prijavnicu i naš medija tim će se javiti u najkraćem mogućem roku.",
      reg_button: "Prijavite se za novinarsku akreditaciju",
      mentions_title: "Članci",
      media_title: "Materijali",
      media_weblica_logotypes: "Weblica - logotipovi (PNG & SVG)",
      media_hacklica_logotypes: "Hacklica - logotipovi (PNG & SVG)",
      media_worklica_logotypes: "Worklica - logotipovi (PNG & SVG)",
      colors_title: "Boje",
    },
    sponsors: {
      title: "Postani sponzor",
      meta: {
        description:
          "Vaša podrška ključna je za visokokvalitetnu realizaciju konferencije koja će okupiti preko 200 sudionika. Sponzorstvom ne samo da doprinosite razvoju IT sektora u regiji, već stječete priliku za promociju Vaše ponude pred relevantnom IT publikom.",
        keywords: "sponzoriranje, sponzor, pomoć, organizacija, doprinos",
      },
      welcome: "Dobrodošli!",
      date_start: "Prijava će biti moguća od {{date1num}} do {{date2num}}",
      date_end: "Prijava više nije moguća! Rok je istekao na dan {{date2num}}",
      btnDownload: "Preuzmi brošuru",
      message: [
        "<p>Hvala na interesu za sponzorstvo.</p>",
        "<p>Molimo Vas da ispunite prijavnicu niže. Nakon što zaprimimo Vaše podatke, na e-mail dobivate brošuru.</p>",
        "<p>Imajte na umu da je rok za prijavu {{date2num}} do kraja dana.</p>",
      ],
      message_download: [
        "<br/><p>Brošuru možete preuzeti i putem gumba ispod.</p>",
      ],
      form: {
        interested_in: "Zanima me još i:",
        interest_visitor: "Posjetiti konferenciju",
        interest_speaker: "Predavati na konferenciji",
        interest_booth: "Postaviti info-pult"
      },
    },
    exhibitors: {
      title: "Postani izlagač",
      meta: {
        description:
          "Vaša podrška ključna je za visokokvalitetnu realizaciju konferencije koja će okupiti preko 200 sudionika. Predstavljanje ne samo da doprinosite razvoju IT sektora u regiji, već stječete priliku za promociju Vaše ponude pred relevantnom IT publikom.",
        keywords: "predstavljanje, pomoć, organizacija, doprinos",
      },
      welcome: "Dobrodošli!",
      date_start: "Prijava će biti moguća od {{date1num}} do {{date2num}}",
      date_end: "Prijava više nije moguća! Rok je istekao na dan {{date2num}}",
      btnDownload: "Preuzmi brošuru",
      message: [
        "<p>Hvala na interesu za predstavljanje.</p>",
        "<p>Molimo Vas da ispunite prijavnicu niže. Nakon što zaprimimo Vaše podatke, na e-mail dobivate brošuru.</p>",
        "<p>Imajte na umu da je rok za prijavu {{date2num}} do kraja dana.</p>",
      ],
      message_download: [
        "<br/><p>Brošuru možete preuzeti i putem gumba ispod.</p>",
      ],
      form: {
        interested_in: "Zanima me još i:",
        interest_visitor: "Posjetiti konferenciju",
        interest_speaker: "Predavati na konferenciji",
        interest_booth: "Postaviti info-pult"
      },
    },
    survey: {
      title: "Upitnik - {{eventName}} {{currentYear}}",
      meta: {
        description: "",
        keywords: "",
      },
      loading: "Priprema u tijeku",
      unknown_event: "Nepoznat događaj!",
      anonymous: "Anonimiziraj osobne podatke",
      anonymous_remark: [
        "<p>Ime i e-mail se šalju kao anonimiziran podatak i služe samo za razlikovanje odgovora na upitnik - bez ikakve mogućnosti identifikacije.</p>",
      ],
      welcome: "Dobrodošli!",
      message: [
        "<p>Hvala na odvajanju vremena za popunjavanje upitnika.<br/>",
        "Iznimno nam je bitno Vaše mišljenje kako bismo ubuduće bili još bolji.</p>",
        "<p>Uz općenita pitanja, u nastavku je moguće ocijeniti pojedinačna predavanja i predavače.</p>",
      ],
      message_speaker: [
        "<p>Hvala na odvajanju vremena za popunjavanje upitnika.<br/>",
        "Iznimno nam je bitno Vaše mišljenje kako bismo ubuduće bili još bolji.</p>",
        "<p>Uz općenita pitanja, u nastavku je moguće ocijeniti pojedinačna predavanja i predavače.</p>",
        '<p>Svi podaci se obrađuju u skladu s našom <a href="{{privacyUrl}}" target="_blank" rel="noreferrer">Politikom privatnosti</a> i koriste se isključivo za poboljšanje budućih događaja.</p>',
      ],
      message_single_use: [
        "Ovaj se upitnik može poslati samo jednom i nije moguće mijenjati odgovore nakon slanja.<br/>Molimo da na pitanja odgovorite što je moguće pažljivije."
      ],
      message_event_not_started: [
        "<p><b>{{eventName}} {{currentYear}}</b> počinje <b>{{date1}}</b> i završava <b>{{date2}}</b>",
        "<p>Upitnik će biti dostupan nakon što događaj završi.</p>"
      ],
      message_event_not_finished: [
        "<p><b>{{eventName}} {{currentYear}}</b> je trenutno aktivan i završava <b>{{date2}}</b>.</p>",
        "<p>Upitnik će biti dostupan nakon što događaj završi.</p>"
      ],
      privacy_statement: [
        // '<p>Svi podaci se obrađuju u skladu s našom <a href="{{privacyUrl}}" target="_blank" rel="noreferrer">Politikom privatnosti</a> i koriste se isključivo za poboljšanje budućih događaja.</p>',
        "<p>Svi podaci se obrađuju u skladu s našom <a href='{{privacyUrl}}' target='_blank' rel='noreferrer'>Politikom privatnosti</a> i koriste se isključivo za dolje navedene svrhe.</p>",
        "<p>Ispunjavanjem upitnika pristajete na prikupljanje i daljnju obradu Vaših osobnih podataka koje ste unijeli u web obrazac <b>„Upitnik - Weblica {{currentYear}}“</b> putem web stranice na web sjedištu <a href='https://weblica.hr/'>https://weblica.hr/</a>, koja uključuje trgovačko društvo WEBLICA j.d.o.o., sa sjedištem u Čakovcu (Grad Čakovec), Športska ulica 6A, OIB: 67304695703 (nadalje u tekstu: „Voditelj obrade“), kao i da Voditelj obrade Vaše osobne podatke prikuplja, obrađuje i koristi u svrhu poboljšanja budućih događaja koji bi bili organizirani od strane Voditelja obrade te u svrhu dijeljenja istih s partnerima/sponzorima konferencije „Weblica“, za ispunjenje marketinških svrha od strane partnera/sponzora Voditelja obrade.</p>",
        "<p>Radi se o dobrovoljnom davanju podataka te privolu niste dužni dati odnosno niste dužni unositi svoje osobne podatke u predmetni web obrazac.",
        "Voditelj obrade potvrđuje da će s osobnim podatcima ispitanika postupati sukladno Uredbi (EU) 2016/679 Europskog parlamenta i Vijeća od 27. travnja 2016. o zaštiti pojedinaca u vezi s obradom osobnih podataka i o slobodnom kretanju takvih podataka te o stavljanju izvan snage Direktive 95/46/EZ (Opća uredba o zaštiti podataka), Zakonu o provedbi Opće uredbe o zaštiti podataka (Narodne novine br. 42/2018), kao i Politici privatnosti zaštite osobnih podataka u WEBLICA j.d.o.o., sve uz primjenu odgovarajućih organizacijskih i tehničkih mjera zaštite osobnih podataka od neovlaštenog pristupa, zlouporabe, otkrivanja, gubitka ili uništenja.</p>",
        "<p>Podatci se prikupljaju trajno iz razloga što do povlačenja privole Voditelj obrade smatra da postoji interes ispitanika za svrhu zbog koje prikuplja, obrađuje i koristi osobne podatke ispitanika. Ispitanik ima pravo u svako doba, u potpunosti ili djelomično, bez naknade i/ili objašnjenja, u bilo kojem trenutku povući privolu, a da to ne utječe na zakonitost obrade koja se temeljila na privoli prije nego što je ona povučena. Ispitanik ima pravo u svako doba, u potpunosti ili djelomično, bez naknade i/ili objašnjenja, od Voditelja obrade ishoditi brisanje osobnih podataka koji se na njega odnose bez nepotrebnog odgađanja. Također, ispitanik ima pravo u svako doba, u potpunosti ili djelomično, bez naknade i/ili objašnjenja, od Voditelja obrade zatražiti pristup osobnim podacima i ispravak ili brisanje osobnih podataka ili ograničavanje obrade koji se odnose na ispitanika ili prava na ulaganje prigovora na obradu takvih te prava na prenosivost podataka. Svoja prava ispitanik može ostvariti obraćanjem Voditelju obrade, odnosno Službeniku za zaštitu osobnih podataka, Davoru Tarandeku, poštom na adresu sjedišta trgovačkog društva WEBLICA j.d.o.o., Športska ulica 6A, 40000 Čakovec ili elektroničkim putem na sljedeću adresu elektroničke pošte: <a href='mailto:info@weblica.hr'>info@weblica.hr</a>. Ispitanik ima pravo na podnošenje prigovora nadzornom tijelu, odnosno Agenciji za zaštitu osobnih podataka (AZOP), Zagreb, Selska cesta 136, adresa elektroničke pošte: <a href='mailto:azop@azop.hr'>azop@azop.hr</a>.</p>"
      ],
      questions: "Pitanja",
      sessions: "Predavanja i predavači",
      speaker: "Predavač",
      speakers_block: "Predavači",
      at: "u",
      form: {
        optional_placeholder: "(komentar nije obavezan)",
        msgs: {
          exists: "Odgovori za upitnik {{eventName}} {{currentYear}} su već poslani!"
        }
      }
    },
    press_register: {
      title: "Prijava za novinarsku akreditaciju",
      meta: {
        description:
          "Prisutnost u modernom medijskom prostoru je svakom događaju poput našeg iznimno bitna, stoga nemojte oklijevati. Ako ste novinar i pratite IT scenu, naši događaji će Vam zasigurno biti zanimljivi.",
        keywords:
          "novinar, novinari, IT, konferencija, radionica, hackathon, weblica, hacklica, worklica",
      },
      welcome: "Dobrodošli!",
      date_start: "Prijava će biti moguća od {{date1num}} do {{date2num}}",
      date_end: "Prijava više nije moguća! Rok je istekao na dan {{date2num}}",
      message: [
        "<p>Hvala na interesu za praćenje naših događaja.</p>",
        "<p>Novinarska akreditacija je podložna procjeni i odobrenju našeg medijskog tima, koji će vas kontaktirati vrlo brzo nakon primitka registracije.</p>",
        "<p>Imajte na umu da je rok za prijavu {{date2num}} do kraja dana.</p>",
        '<p>Ukoliko planirate doći sa cijelim timom (fotograf, snimatelj i td.), samo navedite imena (i eventualno funkciju) u polje "Članovi tima".<p>',
      ],
      form: {
        team_members: "Članovi tima (ime prezime, funkcija)",
        cover: "Želim prisustvovati slijedećim događajima:"
      },
    },
    volunteer: {
      title: "Postani volonter",
      meta: {
        description: "Postani volonter",
        keywords: "volonter, volontiranje, ispomoć, besplatni ulaz",
      },
      welcome: "Dobrodošli!",
      date_start: "Prijava će biti moguća od {{date1num}} do {{date2num}}",
      date_end: "Prijava više nije moguća! Rok je istekao na dan {{date2num}}",
      message: [
        "<p>Volontiranjem pridonosiš uspješnosti događaja, a ujedno možeš sudjelovati u predavanjima koja su ti zanimljiva.</p>",
        "<p>Popuni kratku prijavnicu, a mi ćemo ti se javiti s dodatnim informacijama.</p>",
        "<p>Imajte na umu da je rok za prijavu {{date2num}} do kraja dana.</p>",
      ],
      form: {
      },
    },
    error404: {
      title: "Greška - 404",
      meta: {
        description: "",
        keywords: "",
      },
      message: "Nažalost, tražena stranica ne postoji!",
      home_link: "Početna stranica",
    },
    maintenance: {
      title: "Održavanje",
      meta: {
        description: "",
        keywords: "",
      },
      message:
        "Stranica je na održavanju.<br/>Molimo osvježite je za nekoliko minuta.",
    },
    recording: {
      title: "Obavijest o fotografiranju i snimanju",
      meta: {
        description: "",
        keywords: "",
      },
      text: [
        "<p>Tijekom trajanja naših događaja, naš tim te službeni fotografi i snimatelji bit će prisutni kako bi zabilježili trenutke s događanja. Molimo sudionike da budu svjesni da se fotografiranje, video i audio snimanje može odvijati tijekom cijelog događaja.</p>",
        "<p>Snimljeni materijal može se koristiti u promotivne svrhe, uključujući, ali ne ograničavajući se na, objavljivanje na našim web stranicama, društvenim mrežama i u digitalnim materijalima. Sudjelovanjem na konferenciji dajete nam pravo da koristimo te materijale bez traženja dodatnog odobrenja.</p>",
        "<p>Ako imate bilo kakvih pitanja ili zabrinutosti vezano za ovo, ili ne želite da vaš lik bude zabilježen, molimo vas da se obratite našim organizatorima ili putem e-maila na <a href='mailto:info@weblica.hr' target='_blank'>info@weblica.hr</a>.</p>",
        "<p>Zahvaljujemo na razumijevanju i suradnji!</p>"
      ]
    },
    unsupportedLanguage: {
      title: "Nepodržan ili neaktivan prijevod",
      notSupported: "Prijevod nije podržan ili aktivan!",
      defaultPageLink: `Molimo otvorite <a href="{{originalPath}}">stranicu na zadanom jeziku</a>.`,
      redirecting: "Automatsko usmjeravanje na zadani jezik za {{timeout}}&nbsp;"
    }
  },
  sections: {
    schedule: {
      title: "Raspored",
      title_handle: "Raspored",
      expand_all: "Proširi sve",
      show_starred: "Samo označeno",
      all_tracks: "Skupno",
      all_tracks_button: "Prikaži sve trake",
      track1: "Traka 1",
      track2: "Traka 2",
      track3: "Traka 3",
      track4: "Traka 4",
      nothing_starred: "Nijedna stavka nije označena!",
      language: "Jezik",
      talk_level: "Razina znanja",
      talk_level_novice: "Početnik",
      talk_level_intermediate: "Srednje",
      talk_level_expert: "Stručnjak",
      code_amount: "Količina koda",
      code_amount_none: "Ništa",
      code_amount_light: "Malo",
      code_amount_heavy: "Mnogo",
      tags: "Tagovi",
      work_in_progress: "Više uskoro",
      full_page: "Puna stranica",
      full_page_hint: "Prikaži raspored u posebnoj stranici",
      notification_bell_warning: "Preglednik blokira zvukove. Kliknite na ovo upozorenje kako biste omogućili zvukove!",
      notification_bell: "Zvono",
      notification_bell_hint: "Promjena trenutne stavke će biti najavljena zvučnim signalom.",
      notification_vibrate: "Vibracija",
      notification_vibrate_hint: "Promjena trenutne stavke će biti najavljena vibracijom (samo mobitel).",
      auto_scroll: "Auto Fokus",
      auto_scroll_hint: "Aktivna stavka će se automatski fokusirati",
      powered_by: "Sponzorira",
      tbd: "TBD",
      preview_mode: "Dok mi pripremamo raspored, slobodno pregledajte dio predavanja koja nas očekuju ove godine!",
      aria_starred: "Označeno",
      aria_not_starred: "Nije označeno",
      keynote: "Keynote",
      evaluate_session: "Ocijeni predavanje",
      form: {
        title: "Ocijeni predavanje",
        speakers: "Predavači",
        button_close: "Zatvori",
        button_send: "Pošalji",
        pin: "Upišite PIN posjetitelja/predavača",
        optional_placeholder: "(komentar nije obavezan)",
        msgs: {
          pin_unknown: "Nepoznat PIN!",
          missing_pin: "Nedostaje PIN!",
          missing_stars: "Nedostaju ocjene - dajte barem jednu ocjenu!",
          exists: "Već ste ocijenili ovo predavanje!",
          missing_event: "Nedostaje događaj!",
          missing_year: "Nedostaje godina!",
          missing_session: "Nedostaje predavanje!",
          missing_evaluation: "Nedostaje evaluacija!"
        }
      }
    },
    footer: {
      history: {
        title: "Povijest",
      },
      more: {
        title: "Više",
        code: "Pravila ponašanja",
        terms: "Uvjeti korištenja",
        privacy: "Zaštita privatnosti",
        safety: "Zdravlje i sigurnost",
        press: "Novine i materijali",
        sponsor: "Postani sponzor",
        exhibitor: "Postani izlagač",
        volunteer: "Postani volonter",
        recording: "Obavijest o fotografiranju i snimanju"
      },
      contact: {
        title: "Kontakt",
      },
      legal: {
        title: "Legalne informacije",
        info: "Weblica j.d.o.o., OIB: 67304695703. Upisano u sudski registar Trgovačkog suda u Varaždinu pod brojem 070165706. Temeljni kapital 30,00 HRK uplaćen u cijelosti. Direktor: Davor Tarandek",
        account:
          "IBAN: HR1724020061100898603, SWIFT/BIC: ESBCHR22 kod Erste&Steiermärkische Bank d.d.",
        copyright:
          "{{yearStart}}-{{yearCurrent}} © Weblica j.d.o.o. / Sva prava pridržana",
      },
      created: "Stvoreno i podržano od {{fx}} i {{tria}}",
    },
    sponsors: {
      title: "Sponzori",
      title_handle: "Sponzori",
      titleExhibitors: "Izlagači",
      titleSponsorsAndPartners: "Sponzori i partneri",
      btnDownload: "Preuzmi brošuru",
      btnRegister: "Postani sponzor",
      btnRegisterExhibitor: "Postani izlagač",
      partners: "Partneri",
      mediaPartners: "Medijski pokrovitelji",
      levels: {
        l5: "Platinasti",
        l4: "Zlatni",
        l3: "Srebrni",
        l2: "Brončani",
        l1: "?",
      },
      packages: {
        web: "Web",
        lunch: "Ručak",
        coffee: "Kava",
        snacks: "Grickalice",
        accreditations: "Akreditacije",
        water: "Brendirana voda",
        soft_drinks: "Pića",
        pens: "Olovke",
        notepads: "Bilježnice",
        photo: "Fotograf portreta"
      },
    },
    news: {
      title: "Novosti",
      title_handle: "Novosti",
      expand_all: "Proširi sve",
    },
    faq: {
      title: "Često postavljana pitanja",
      title_handle: "Često postavljana pitanja",
      expand_all: "Proširi sve",
    },
    testimonials: {
      title: "Dojmovi",
      title_handle: "Dojmovi",
      expand_all: "Proširi sve",
      speaker: "Predavač",
    },
    historyGallery: {
      title: "Povijest",
      title_handle: "Povijest",
      content: [
        "<p>Prošle konferencije i ostali događaji su bili iznimno zanimljivi - provjerite zašto.</p>",
      ],
      aria_item_image: "Slika za primjer"
    },
    newsletter: {
      title: "Obavijesti",
      title_handle: "Obavijesti",
      content: "Pridruži se našoj mailing listi i budi u tijeku.",
      form: {
      },
    },
  },
  weblica: {
    meta: {
      titlePrefix: "Konferencija o web tehnologijama",
      description: "Konferencija o web tehnologijama",
      keywords:
        "weblica, konferencija, programer, programiranje, development, software, softver, devops, web3, php, javascript, node, .net, css, html, react, vue, angular, razvoj, kolaboracija, dizajn, implementacija",
    },
    menu: {
      about: "O događaju",
      news: "Novosti",
      speakers: "Predavači",
      schedule: "Raspored",
      tickets: "Ulaznice",
      venue: "Lokacija",
      sponsors: "Sponzori",
      faq: "ČPP",
      testimonials: "Dojmovi",
    },
    buttons: {
      home: "/",
      tickets: "Naruči ulaznice",
      lecture: "Prijavi predavanje",
      sponsor: "Postani sponzor",
      exhibitor: "Postani izlagač",
      volunteer: "Postani volonter",
    },
    pages: {
      speaker: {
        intro: "Imam predavanje na konferenciji <span>{{event}} {{year}}</span>"
      },
      history: {
        meta: {
          description: "Povijest Weblice",
          keywords:
            "weblica, povijest, predavači, predavanja, video, fotografije",
        },
        title: "Povijest Weblice",
        talks: "Predavanja i predavači",
        no_data: "Nedostaju podaci!",
        browse_images: "Pregledajte slike s događaja",
        images: "Fotogalerija",
        when_where: "Kad i gdje",
        when: "Datum",
        where: "Lokacija",
        sponsors_partners: "Sponzori i partneri",
      },
    },
    sections: {
      hero: {
        title: "Weblica",
        // subtitle: "Konferencija o web tehnologijama",
        // tags: ["Razvoj", "Kolaboracija", "Dizajn", "Implementacija"],
        intro: [],
        preparation: [
          'Prati nas ovdje ili se pridruži našoj <a href="#newsletter">mail listi</a> i budi u tijeku!',
        ],
      },
      about: {
        title: "",
        title_handle: "O događaju",
        txt_aaa: [
          "<p>Weblica stvara platformu za razvoj IT sektora u regiji, privlačeći širok spektar sudionika; od developera i dizajnera do menadžera svih vrsta, DevOps stručnjaka i studenata, te svih onih koji su zainteresirani za svijet web tehnologija.</p>",
          "<p>Fokus Weblice je na pružanju vrijednog i dubinskog znanja u prijateljskom okruženju, dok su predavanja neiscrpan izvor praktičnih rješenja.</p>",
          "<p>Aktivno sudjelovanje u interaktivnim razgovorima i povezivanje s istomišljenicima omogućit će vam da odmah primijenite stečeno znanje u praksi.</p>",
        ],
        txt_bbb: [
          "<div class='h3'>Smjernice kod odabira tema</div>",
          "<p>Weblica, kao konferencija o web tehnologijama, obuhvaća široki spektar potencijalnih tema za obradu. Ovaj popis tema služi kao vodič za sadržaj koji će se obrađivati na konferenciji, ali <b>nije</b> ograničen na navedena područja. U svijetu web tehnologija postoji mnoštvo informacija i perspektiva, stoga rado pozivamo prijedloge tema koje nisu navedene, a imaju značajnu vrijednost u IT sektoru.</p>",
          "<p>Budući da je naša publika sastavljena od stručnjaka u industriji, pozivamo predavače da se ne ustručavaju zaroniti u kompleksne tehničke detalje u svojim predavanjima.</p>",
          "<ul class='spec-box-tag-cloud {{event}}'>",
          "{{eventTopicsTags}}",
          "</ul>",
        ],
        txt_ccc: [
          "<div class='h3'>Dosadašnja izdanja u brojkama</div>",
          "<ul class='spec-box-numbers'>",
          "<li><span>{{statVisitors}}+</span> posjetitelja</li>",
          "<li><span>{{statSpeakers}}+</span> stručnih predavača</li>",
          "<li><span>{{statBeers}}+</span> popijenih litara piva</li>",
          // "<li><span>{{statDrinks}}+</span> popijenih komada bezalkoholnih pića (kave, sokovi)</li>",
          // "<li><span>{{statBurgers}}+</span> pojedenih gourmet burgera</li>",
          "<li><span>&infin;</span> znanja i inspiracije</li>",
          "</ul>",
          "<p>Kvalitetna hrana, sjajna kava i odabrana piva čine dodatan razlog zašto posjetiti Weblicu.</p>",
        ],
      },
      speakers: {
        title: "Predavači",
        title_handle: "Predavači",
        register: "Prijavi predavanje",
        work_in_progress: "Više uskoro",
        keynote: "Keynote",
        expand_all: "Proširi sve",
      },
      tickets: {
        title: "Ulaznice",
        title_handle: "Ulaznice",
        description: "Osigurajte svoje mjesto na Weblici {{currentYear}}. i budite dio naše zajednice.",
        stream: "Prijenos",
        live: "Konferencija uživo",
        goodies: "Poklon paket",
        extra_goodies: "Dodatci u poklon paketu",
        lunch: "Ručak, grickalice i pića",
        activity_day: "Aktivan Dan",
        order_now: "Naruči",
        ticket_not_available: "Više nije dostupno!",
        plus_vat: " + PDV",
        from: "od",
        to: "do",
        now: "&#9737;",
        remaining_tickets:
          "Broj dostupnih ulaznica: <b>{{remainingTickets}}</b>",
        remaining_tickets_off:
          "<b>Žao nam je, ali nema više dostupnih ulaznica!</b>",
        price_alt_remark:
          "Preračunato po fiksnom tečaju od {{altConversionRate}}",
        price_vat_remark: "Cijene uključuju PDV",
      },
      venue: {
        title: "Lokacija",
        title_handle: "Lokacija",
        map_link_text_om: "Prikaži Open Street kartu u novoj kartici",
        map_link_text_gm: "Prikaži Google kartu u novoj kartici",
        parking: "Parkiralište",
        parking_text:
          "U sklopu objekta u kojem će se održavati konferencija omogućeno je besplatno parkiralište za sve sudionike.",
        accommodation: "Smještaj",
        accommodation_text:
          "Ako trebate pomoć ili preporuke u vezi smještaja, slobodno nam se obratite.",
        accessibility: "Pristupačnost",
        accessibility_text: "Pristup za osobe s invaliditetom je osiguran.",
      },
    },
  },
  hacklica: {
    meta: {
      titlePrefix: "24-satni hackathon",
      description: "24-satni hackathon",
      keywords: "hachathon, angažman, 24 sata, nagrade, novac, prestiž",
    },
    menu: {
      about: "O događaju",
      news: "Novosti",
      topics: "Teme",
      venue: "Lokacija",
      sponsors: "Sponzori",
      faq: "ČPP",
      terms: "Uvjeti",
      policy: "Politika",
    },
    buttons: {
      home: "/",
      register: "Prijavi svoj tim",
      sponsor: "Postani sponzor",
    },
    pages: {
      register: {
        meta: {
          description: "Prijava tima za hackathon",
          keywords: "hackathon, prijava, tima",
        },
        title: "Prijava tima",
        date_start: "Prijava će biti moguća od {{date1num}} do {{date2num}}",
        date_end:
          "Prijava više nije moguća! Rok je istekao na dan {{date2num}}",
        maxedOut: [
          "<p>Broj prijavljenih timova je dosegao unaprijed određen broj!<br/>",
          "Žao nam je, ali niste bili dovoljno brzi!</p>",
        ],
        message: [
          "<p>Drago nam je da ste se odlučili natjecati na našem hackathonu!</p>",
          "<p>Nakon slanja podataka, primit ćete potvrdni e-mail. Molimo da slijedite poveznicu u poruci kako biste dovršili prijavu.</p>",
          '<p>Preporučujemo da, prije slanja prijave, pročitate naša <a href="{{code}}" target="_blank" rel="noreferrer">Pravila ponašanja</a> te <a href="{{terms2}}" target="_blank" rel="noreferrer">Uvjete Hackathona</a>.</p>',
          "<p>Prijava je omogućena do kraja dana <b>{{date2num}}</b> a broj timova je ograničen na <b>{{maxTeams}}</b>.</p>",
        ],
        form: {
          team_name: "Naziv tima",
          team_email: "E-mail",
          team_count: "Ljudi u timu",
          member_name: "Puno ime",
          member_tshirt: "Veličina majce",
          member_vege: "Vege",
          member_lead: "Voditelj tima",
          shirts_info: "Sve majce će biti personalizirane sa nazivom tima!",
          info_text:
            'Prije slanja preporučujemo provjeriti našu <a href="{{url}}" target="_blank" rel="noreferrer">Politiku privatnosti</a> o tome kako upravljamo privatnim podacima.',
          msgs: {
            missing_team_name: "Nedostaje naziv tima!",
            missing_team_email: "Nedostaje e-mail tima!",
            missing_team_lead: "Niste odabrali voditelja tima!",
            missing_member_name: "Nedostaje ime člana!",
            invalid_team_email_format: "E-mail tima nije pravilno upisan!",
            invalid_team_count_max: "Broj timova je dosegnuo maksimum!",
          },
        },
      },
      confirmation: {
        meta: {
          description: "Potvrda registracije za Hackathon",
          keywords: "hackathon, potvrda, registracija, registracije",
        },
        title: "Vaša prijava na hackathon Hacklica je uspješna!",
        at: "u",
        message: [
          "<p>Uspješno ste prijavili svoju ekipu na hackathon Hacklica.</p>",
          "<p>Kratak podsjetnik - početak je <b>{{date}} {{at}} {{time}}</b> na lokaciji <b>{{venue}}</b>.</p>",
          '<p>Hrana, piće i sadržaji za razonodu su osigurani, jedino što morate ponijeti je oprema za rad. <span class="msg">*</span></p>',
          "<p><b>Želimo vam puno uspjeha!</b></p>",
          '<p class="msg">* podložno izmjenama</p>',
        ],
        __message: [
          "<p><b>Hvala na prijavi za Hacklica Hackathon!</b></p>",
          "<p>Ovime je vaša registracija potvrđena te vas očekujemo dana {{date}} {{at}} {{time}}!</p>",
          "<p>Ako niste u mogućnosti prisustvovati, molimo da se javite barem nekoliko dana prije hackathona.</p>",
        ],
      },
      history: {
        meta: {
          description: "Povijest Hacklice",
          keywords: "hacklica, hackathon, 24 sata, povijest",
        },
        title: "Povijest Hacklice",
        teams: "Ekipe",
        no_data: "Nedostaju podaci!",
        browse_images: "Pregledajte slike s događaja",
        images: "Fotogalerija",
        when_where: "Kad i gdje",
        when: "Datum",
        where: "Lokacija",
        sponsors_partners: "Sponzori i partneri",
      },
      terms: {
        meta: {
          description: "Hacklica Hackathon Uvjeti",
          keywords: "hacklica, hackathon, uvjeti",
        },
        title: "Hacklica Hackathon Uvjeti",
      },
    },
    sections: {
      hero: {
        title: "Hacklica",
        subtitle: "24-satni hackathon",
        tags: [],
        __intro: [
          "<p>Hacklica je mjesto gdje se potiče beskrajna kreativnost i programerska strast! Radi se o uzbuđujućem hackathonu koji traje 24 sata, gdje se najsvjetliji umovi iz regije okupljaju kako bi izazvali granice inovacije.</p>",
        ],
        preparation: [
          'Prati nas ovdje ili se pridruži našoj <a href="#newsletter">mail listi</a> i budi u tijeku!',
        ],
        txt_1: [
          "<p>Timovi svakim hackathonom kreću na uzbudljivo putovanje u kojem se razvijaju napredna rješenja, guraju kreativne granice i ideje pretvaraju u stvarnost. Sat otkucava, ali mogućnosti su beskrajne!</p>",
          "<p>Inovacija je glavna ideja vodilja i od timova se ne očekuje gotovo rješenje, ali se i dalje traži maksimalan angažman koji će mjerljivo prikazati sposobnosti i mogućnosti tima.</p>",
        ],
        txt_2: [
          "<p>Naš stručni žiri se sastoji od iskusnih stručnjaka koji svako rješenje ocjenjuju na nekoliko razina - od kvalitete i izvedbe rješenja, kvalitete napisanog koda pa do same prezentacije.</p>",
        ],
      },
      about: {
        title: "",
        title_handle: "O događaju",
        txt_aaa: [
          "<p><b>Borba za glavnu nagradu</b><br/>",
          "Timovi se natječu za impresivne novčane nagrade koje nikoga neće ostaviti ravnodušnima. Uz to, naravno da svi sudionici, a posebno pobjednici, imaju legitimni razlog da se pohvale pred svojim kolegama i prijateljima, ali i budućim poslodavcima.</p>",
          "<p><b>Inspirativne teme</b><br/>",
          "Uvijek postoji odabir uzbudljivih i aktualnih tema koje donekle usmjeravaju što će se raditi, koje su dovoljno slobodne da potaknu vašu maštovitost i istaknu vaše tehničke vještine. Svaki tim samostalno odabire jednu od ponuđenih tema i ima priliku pokazati svoje sposobnosti.</p>",
          "<p><b>Hrana i piće su osigurani, kao i brojni načini razonode</b><br/>",
          "Vaša udobnost nam je prioritet. Zato su osigurane dostatne količine raznih ukusnih obroka, od mesnih do vege jela, te bezalkoholnih pića. Naravno, podrazumijeva se da ćete se moći brzinski napuniti energijom raznim grickalicama i voćem. Ako imate posebne želje, samo nam javite i mi ćemo se potruditi to osigurati.</p>",
          "<p>Što se razonode tiče, pripremili smo hrpu zabavnih aktivnosti i iznenađenja, tako da ćete se moći opustiti i povezati s drugima, bilo da ste ljubitelj igara, sporta ili opuštanja uz prijateljski razgovor.</p>",
        ],
      },
      topics: {
        title: "Teme",
        register_team: "Prijavi svoj tim",
        message: [
          "<p>Ovdje navedene teme namijenjene su orijentaciji timovima koji planiraju prisustvovati.</p>",
          "<p>Ovo otkrivamo samo da vam malo pomognemo i da se mentalno pripremite za hackathon. Molimo vas da ne dolazite u iskušenje i razvijete dio ili cijelo rješenje prije samog događaja. Znat ćemo, diskvalificirat ćemo vas, posramiti vas javno i povrh svega, zabranit ćemo vam pristvovanje na svim našim budućim događajima. Doživotno.</p>",
          "<hr/>",
        ],
      },
      venue: {
        title: "Lokacija",
        title_handle: "Lokacija",
        map_link_text_om: "Prikaži Open Street kartu u novoj kartici",
        map_link_text_gm: "Prikaži Google kartu u novoj kartici",
        parking: "Parkiralište",
        parking_text:
          "U sklopu objekta u kojem će se održavati konferencija omogućeno je besplatno parkiralište za sve sudionike.",
        accommodation: "Smještaj",
        accommodation_text:
          "Ako trebate pomoć ili preporuke u vezi smještaja, slobodno nam se obratite.",
        accessibility: "Pristupačnost",
        accessibility_text: "Pristup za osobe s invaliditetom je osiguran.",
      },
    },
    timer: {
      init: "Inicijalizacija...",
      waiting: "Čeka se početak!",
      waiting_days: "Pripremite se! Krećemo za par dana!",
      waiting_long: "Ima još mnogo vremena do početka!",
      in_progress: "Hackathon je u tijeku!",
      done: "Hackathon je gotov! Hvala svima!",
      hr: "hr",
      min: "min",
      sec: "sec",
      msec: "ms",
    },
  },
  worklica: {
    meta: {
      titlePrefix: "Radionice za developere",
      description: "Radionice za developere",
      keywords: "worklica, radionica, radionice, developer, developeri",
    },
    menu: {
      about: "O događaju",
      news: "Novosti",
      speakers: "Predavači",
      schedule: "Raspored",
      tickets: "Ulaznice",
      venue: "Lokacija",
      sponsors: "Sponzori",
      faq: "ČPP",
    },
    buttons: {
      home: "/",
      tickets: "Naruči ulaznice",
      register: "Prijavi radionicu",
      sponsor: "Postani sponzor",
    },
    pages: {
      history: {
        meta: {
          description: "Povijest Worklice",
          keywords: "worklica, radionica, radionice, povijest",
        },
        title: "Povijest Worklice",
        talks: "Radionice i predavači",
        no_data: "Nedostaju podaci!",
        browse_images: "Pregledajte slike s događaja",
        images: "Fotogalerija",
        when_where: "Kad i gdje",
        when: "Datum",
        where: "Lokacija",
        sponsors_partners: "Sponzori i partneri",
      },
    },
    sections: {
      hero: {
        title: "Worklica",
        subtitle: "Radionice za developere",
        tags: [],
        __intro:
          "Worklica je proširenje konferencije za sve koji vole biti proaktivniji i učiti kroz praktične primjere.",
        preparation: [
          'Prati nas ovdje ili se pridruži našoj <a href="#newsletter">mail listi</a> i budi u tijeku!',
        ],
        txt_1: [
          "<p>Uz stručne predavače odnosno voditelje svake pojedine radionice, polaznici kroz praktične primjere na licu mjesta isprobavaju nove ideje, tehnike kao i same koncepte rada i razmišljanja.</p>",
          "<p>Većina nas lakše i osjetno dulje zadržava naučeno učeći kroz praksu, stoga radionice imaju dodanu vrijednost, ali traže dodatan angažman za kvalitetno praćenje.</p>",
        ],
        txt_2: [
          "<p>Za praćenje radionice će svakako biti potrebno radno računalo, ali ukoliko iz nekog razloga niste u mogućnosti ponijeti svoju opremu, javite nam se na vrijeme, pa ćemo dati sve od sebe da Vam omogućimo neko privremeno rješenje.</p>",
          "<p>Ukoliko sama radionica ima neke specifične tehničke zahtjeve, to će biti jasno istaknuto u samom rasporedu radionica.</p>",
        ],
      },
      about: {
        title: "",
        title_handle: "O događaju",
        txt_aaa: [
          "<p>Iako smo, iz poznatih razloga, uspjeli održati samo jednu Worklicu, ona se pokazala zanimljiva određenom broju ljudi, stoga svakako nastavljamo s novim zanosom i novim idejama.</p>",
          "<p>Znamo da je teško odabrati između dvije dobre i zanimljive opcije pa ćemo stoga osigurati se Weblica i Worklica neće održavati u isto vrijeme. Time omogućavamo polaznicima da prisustvuju na oba događaja bez potrebe za biranjem.</p>",
        ],
      },
      speakers: {
        title: "Predavači",
        title_handle: "Predavači",
        register: "Prijavi radionicu",
      },
      tickets: {
        title: "Ulaznice",
        title_handle: "Ulaznice",
        worklica: "Radionice",
        goodies: "Poklon paket",
        lunch: "Ručak, grickalice i pića",
        order_now: "Naruči",
        ticket_not_available: "Više nije dostupno!",
        plus_vat: " + PDV",
      },
      venue: {
        title: "Lokacija",
        title_handle: "Lokacija",
        map_link_text_om: "Prikaži Open Street kartu u novoj kartici",
        map_link_text_gm: "Prikaži Google kartu u novoj kartici",
        parking: "Parkiralište",
        parking_text:
          "U sklopu objekta u kojem će se održavati konferencija omogućeno je besplatno parkiralište za sve sudionike.",
        accommodation: "Smještaj",
        accommodation_text:
          "Ako trebate pomoć ili preporuke u vezi smještaja, slobodno nam se obratite.",
        accessibility: "Pristupačnost",
        accessibility_text: "Pristup za osobe s invaliditetom je osiguran.",
      },
    },
  },
};
